import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  SettingsTicketDefaults,
  SettingsTicketFormatting,
  SettingsTicketNumber,
  SettingsTicketInputDefaults,
  SettingsTicketDefaultsFormValues,
  SettingsTicketNumberFormValues,
  SettingsTicketInputDefaultsFormValues,
  SettingsTicketFormattingFormValues,
  SettingsWorkflow,
  SettingsWorkflowFormValues,
  RecentTickets,
  TicketHistoryItem,
  OrderDirection,
  RecentTicketSortKeys,
} from "../models/ticket";
import { TicketType, TicketWorkflowSteps } from "../models/enums";
import { Pagination, PagingParams } from "../models/pagination";

export default class TicketStore {
  loading = false;
  loadingInitial = false;
  activeTab: TicketWorkflowSteps = 0;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  recentTicketsSortPredicate = new Map();
  recentTicketsSorting = false;
  recentTicketsRegistry = new Map<string | undefined, RecentTickets>();
  ticketHistoryRegistry = new Map<string | undefined, TicketHistoryItem>();

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();

        this.recentTicketsRegistry.clear();
        this.ticketHistoryRegistry.clear();
        this.loadTickets();
      }
    );
  }

  resetTicketRegistries = () => {
    this.recentTicketsRegistry.clear();
    this.ticketHistoryRegistry.clear();
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  selectedSettingsTicketDefaults: SettingsTicketDefaultsFormValues = {
    id: undefined,
    showLaborHours: false,
    showReturnOldParts: false,
    rateTypes: 1,
    defaultPayTypeId: undefined,
    showSeparateCharges: false,
    promisedDateOptions: 1,
    estimateCharge: 0,
  };

  selectedSettingsTicketFormatting: SettingsTicketFormattingFormValues = {
    id: undefined,
    showBorderOnPrint: false,
    shadeAlternatingRows: false,
    customerSignatureLine: 0,
    showNotesLabel: false,
    showPayment: false,
    showRemoveAndReplace: false,
    logoPlacement: 0,
    showPONumber: false,
    showEstimateNumber: false,
  };

  selectedSettingsTicketNumber: SettingsTicketNumberFormValues = {
    id: undefined,
    invoiceNumber: 0,
    estimateNumber: 0,
    autoPONumber: 0,
    assignAutoPO: false,
  };

  selectedSettingsTicketInputDefaults: SettingsTicketInputDefaultsFormValues = {
    id: undefined,
    capitalizeFirstCharacter: false,
    formatPhoneNumber: false,
    defaultCity: "",
    defaultState: "",
    defaultPostalCode: "",
  };

  selectedWorkflow: SettingsWorkflowFormValues = {
    id: undefined,
    isCustomerInfoFirst: false,
    defaultTicketType: 0,
    numberIncrementOptions: "",
    allowCustomVehicle: false,
    error: undefined,
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    return params;
  }

  loadSettingsTicketDefaults = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Tickets.getSettingsTicketDefaults();
      runInAction(() => {
        this.selectedSettingsTicketDefaults = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadSettingsTicketFormatting = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Tickets.getSettingsTicketFormatting();
      runInAction(() => {
        this.selectedSettingsTicketFormatting = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadSettingsTicketNumber = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Tickets.getSettingsTicketNumber();
      runInAction(() => {
        this.selectedSettingsTicketNumber = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadSettingsTicketInputDefaults = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Tickets.getSettingsTicketInputDefaults();
      runInAction(() => {
        this.selectedSettingsTicketInputDefaults = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadSettingsWorkflow = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Tickets.getSettingsWorkflow();
      runInAction(() => {
        this.selectedWorkflow = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setSelectedSettingsTicketDefaults = (
    values: SettingsTicketDefaultsFormValues
  ) => {
    this.selectedSettingsTicketDefaults = values;
  };

  setSelectedSettingsWorkflow = (values: SettingsWorkflowFormValues) => {
    this.selectedWorkflow = values;
  };

  setSelectedSettingsTicketNumber = (
    values: SettingsTicketNumberFormValues
  ) => {
    this.selectedSettingsTicketNumber = values;
  };

  setSelectedSettingsTicketFormatting = (
    values: SettingsTicketFormattingFormValues
  ) => {
    this.selectedSettingsTicketFormatting = values;
  };

  setSelectedSettingsTicketInputDefaults = (
    values: SettingsTicketInputDefaultsFormValues
  ) => {
    this.selectedSettingsTicketInputDefaults = values;
  };

  createTicketDefaults = async (values: SettingsTicketDefaultsFormValues) => {
    try {
      let myNew: SettingsTicketDefaults = new SettingsTicketDefaults(values);

      await agent.Tickets.addSettingsTicketDefaults(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketDefaults(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  createSettingsWorkflow = async (values: SettingsWorkflowFormValues) => {
    try {
      let myNew: SettingsWorkflow = new SettingsWorkflow(values);
      await agent.Tickets.addSettingsWorkflow(myNew);

      runInAction(() => {
        this.setSelectedSettingsWorkflow(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  createSettingsTicketNumber = async (
    values: SettingsTicketNumberFormValues
  ) => {
    try {
      let myNew: SettingsTicketNumber = new SettingsTicketNumber(values);
      await agent.Tickets.addSettingsTicketNumber(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketNumber(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  createSettingsTicketFormatting = async (
    values: SettingsTicketFormattingFormValues
  ) => {
    try {
      let myNew: SettingsTicketFormatting = new SettingsTicketFormatting(
        values
      );
      await agent.Tickets.addSettingsTicketFormatting(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketFormatting(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  createSettingsTicketInputDefaults = async (
    values: SettingsTicketInputDefaultsFormValues
  ) => {
    try {
      let myNew: SettingsTicketInputDefaults = new SettingsTicketInputDefaults(
        values
      );
      await agent.Tickets.addSettingsTicketInputDefaults(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketInputDefaults(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateTicketDefaults = async (values: SettingsTicketDefaultsFormValues) => {
    try {
      let myNew: SettingsTicketDefaults = new SettingsTicketDefaults(values);
      await agent.Tickets.updateSettingsTicketDefaults(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketDefaults(values);
      });
    } catch (error) {
      console.log(error);
    }
  };
  updateSettingsWorkflow = async (values: SettingsWorkflowFormValues) => {
    try {
      let myNew: SettingsWorkflow = new SettingsWorkflow(values);
      await agent.Tickets.updateSettingsWorkflow(myNew);

      runInAction(() => {
        this.setSelectedSettingsWorkflow(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsTicketNumber = async (
    values: SettingsTicketNumberFormValues
  ) => {
    try {
      let myNew: SettingsTicketNumber = new SettingsTicketNumber(values);
      await agent.Tickets.updateSettingsTicketNumber(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketNumber(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsTicketFormatting = async (
    values: SettingsTicketFormattingFormValues
  ) => {
    try {
      let myNew: SettingsTicketFormatting = new SettingsTicketFormatting(
        values
      );
      await agent.Tickets.updateSettingsTicketFormatting(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketFormatting(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsTicketInputDefaults = async (
    values: SettingsTicketInputDefaultsFormValues
  ) => {
    try {
      let myNew: SettingsTicketInputDefaults = new SettingsTicketInputDefaults(
        values
      );
      await agent.Tickets.updateSettingsTicketInputDefaults(myNew);

      runInAction(() => {
        this.setSelectedSettingsTicketInputDefaults(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  get ticketsByDate() {
    return Array.from(this.recentTicketsRegistry.values()).sort((a, b) => {
      return (
        this.getDate(
          b.ticketType ?? TicketType.Estimate,
          b.estimateDate,
          b.invoiceDate
        ).getTime() -
        this.getDate(
          a.ticketType ?? TicketType.Estimate,
          a.estimateDate,
          a.invoiceDate
        ).getTime()
      );
    });
  }

  getDate(
    ticketType: TicketType,
    estimateDate: Date | undefined,
    invoiceDate: Date | undefined
  ) {
    let date = new Date();
    if (ticketType === TicketType.Estimate && estimateDate) date = estimateDate;
    if (ticketType === TicketType.Invoice && invoiceDate) date = invoiceDate;

    return date;
  }

  loadTickets = async () => {
    this.loadingInitial = true;
    try {
      this.recentTicketsRegistry.clear();
      const tickets = await agent.Tickets.listRecentTickets(this.axiosParams);
      runInAction(() => {
        tickets.data.forEach((ticket) => {
          this.setRecentTicket(ticket);
        });
        this.setPagination(tickets.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setRecentTicket = (ticket: RecentTickets) => {
    ticket.customerDateCreated = new Date(ticket.customerDateCreated!);
    if (ticket.invoiceDate) ticket.invoiceDate = new Date(ticket.invoiceDate);
    if (ticket.estimateDate)
      ticket.estimateDate = new Date(ticket.estimateDate);
    this.recentTicketsRegistry.set(ticket.ticketId, ticket);
  };

  resetRecentTickets = () => {
    this.recentTicketsRegistry.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadTicketHistoryItems = async (ticketId: string) => {
    this.loadingInitial = true;
    try {
      this.ticketHistoryRegistry.clear();
      const result = await agent.Tickets.listTicketHistory(ticketId);
      runInAction(() => {
        result.forEach((ticket) => {
          this.setTicketHistory(ticket);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setTicketHistory = (ticket: TicketHistoryItem) => {
    if (ticket.createdDate) ticket.createdDate = new Date(ticket.createdDate);

    this.ticketHistoryRegistry.set(ticket.id, ticket);
  };

  resetTicketHistoryRegistry = () => {
    this.ticketHistoryRegistry.clear();
  };

  get ticketHistory() {
    return Array.from(this.ticketHistoryRegistry.values()).sort(
      (a, b) => a.createdDate!.getTime() - b.createdDate!.getTime()
    );
  }

  getTicketPdf = async (id: string) => {
    return await agent.Tickets.getTicketPdf(id);
  };

  getIsInProgress = async (id: string) =>  {

    let returnValue:string = "";
    try {
      const result = await agent.Tickets.getTicketInProgressStatus(id);
      returnValue = result;
      runInAction(() => {

          return returnValue;
      });
    } catch (error) {
      console.log(error);
    } 
    finally{
      return returnValue;
    }
  }

  
  get recentTicketsAxiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.recentTicketsSortPredicate.forEach((value, key) =>  params.append(key, value));
    return params;
  }

  setRecentTicketsSortPredicate = (
    predicate: string,
    value: string | Date | number | OrderDirection | RecentTicketSortKeys | undefined
  ) => {
    if(value) {
      this.recentTicketsSortPredicate.set(predicate, value);
    } else {
      this.recentTicketsSortPredicate.delete(predicate);
    }
  };

  setRecentTicketsSorting = (val: boolean) => {
    this.recentTicketsSorting = val;
  }
}
