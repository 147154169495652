import { observer } from "mobx-react-lite";
import React from "react";
import { Grid, Button, Message, MessageHeader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../app/stores/store";
import PageHeader from "../../../../app/layout/PageHeader";
import { TicketWorkflowSteps } from "../../../../app/models/enums";

interface Props {
  ticketId: string;
  userName: string;
}

export default observer(function AlreadyInProgress({ ticketId, userName }: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const navigate = useNavigate();
  const { modalStore, ticketSessionStore, userStore } = useStore();
  const { resetTicketSession, updateTicketSessionUser } = ticketSessionStore;
  const { user } = userStore;

  const isDiffUser = `${user?.firstName} ${user?.lastName}` !== userName;
  console.log(userName);

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <PageHeader
            type={"h1"}
            header={
              t(isDiffUser ? "pops.diffUser" : "pops.alreadyProgress", {
                ns: "tickets",
              }) + ":"
            }
            divider={true}
            addTitle={false}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Message info>
            <MessageHeader>
              {t(isDiffUser ? "pops.aipDiffUser" : "pops.aipMessage", {
                ns: "tickets",
                userName: userName,
              })}
            </MessageHeader>
          </Message>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          {!isDiffUser && (
            <>
            <Button
              color="green"
              icon="check"
              content={t("pops.aip_button_load&keep", {
                ns: "tickets",
              })}
              onClick={() => {
                updateTicketSessionUser(ticketId).then(() => {
                  navigate(`/ticket/summary/${ticketId}/true`);
                  modalStore.closeModal();
                });
              }}
            />
          <Button
            color="red"
            icon="cancel"
            content={t("pops.aip_button_load&delete", {
              ns: "tickets",
            })}
            onClick={() => {
              resetTicketSession(ticketId, TicketWorkflowSteps.Summary)
                .then(() => {
                  navigate(`/ticket/summary/${ticketId}/true`);
                })
                .catch((error) => {
                  console.log(error);
                });
              modalStore.closeModal();
            }}
          />
            </>
          )}
            <Button
              color="grey"
              content={t(isDiffUser ? "close" : "cancel", {
                ns: "common",
              })}
              onClick={() => {
                modalStore.closeModal();
              }}
            />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
