import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Icon, Input, Dropdown, Menu, Header } from "semantic-ui-react";
import { Nav, Navbar } from "react-bootstrap";
import { useStore } from "../stores/store";
import { useTranslation } from "react-i18next";
import logo from "../../app/common/img/logo.png";
import { searchOptions } from "../common/options/searchOptions";

import {
  AccessLevel,
  Application,
  ClientStatus,
  SearchType,
} from "../models/enums";
import { resetTicket } from "../common/util/ticketfunctions";
import Auth from "../common/util/auth";

export default observer(function NavBar() {
  const {
    userStore,
    searchStore,
    ticketStore,
  } = useStore();
  const { t } = useTranslation(["common", "users"]);
  const location = useLocation();

  const navigate = useNavigate();

  const {
    user,
    logout,
    isLoggedIn,
    checkStatus,
    checkApplication,
    checkAccessLevel,
    restoreUser,
  } = userStore;

  const { setPredicate, resetSearchRegistry } = searchStore;

  const { resetRecentTickets } = ticketStore;

  const [searchValue, setSearchValue] = useState<string>();
  const [dropdownValue, setDropdownValue] = useState<SearchType>(
    SearchType.Site
  );

  const [colorChange, setColorChange] = useState(false);
  const [expanded, setExpanded] = useState(false);

  function search(searchValue: string, state: SearchType) {
    if (searchValue && state > SearchType.None) {
      resetSearchRegistry();
      setPredicate(state, searchValue);

      navigate(`/search/${state}/${searchValue}`);

      setSearchValue("");
      setDropdownValue(10);
    }
  }

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  return (
    <Navbar
      className={colorChange ? "navbar colorChange" : "navbar"}
      variant="dark"
      expand="lg"
      fixed="top"
      expanded={expanded}
    >
      <>
        <Navbar.Brand className="navLogo navbarPadding">
          <Image href="/" src={logo} width="175" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          {isLoggedIn ? (
            <>
              {user &&
                user.status === ClientStatus.PreTrial &&
                checkAccessLevel(AccessLevel.Owner) && (
                  <Nav>
                    <Nav.Link
                      id="pre-trial.settings"
                      href="/settings"
                      onClick={() => {
                        setExpanded(false);
                      }}
                    >
                      <Icon name="settings" />
                      {t("setupShopSettings", { ns: "subscription" })}
                    </Nav.Link>
                  </Nav>
                )}
              {user &&
                user.status ===
                  (ClientStatus.SignUpInProgress | ClientStatus.Active) &&
                checkAccessLevel(AccessLevel.Owner) && (
                  <Nav navbarScroll>
                    <Nav.Link
                      id="trials"
                      href="/trials"
                      onClick={() => {
                        setExpanded(false);
                      }}
                    >
                      <Icon name="car" />
                      {t("startYourTrial", { ns: "subscription" })}
                    </Nav.Link>
                  </Nav>
                )}
              {checkStatus(ClientStatus.Trial | ClientStatus.Active) && (
                <>
                  <Nav navbarScroll>
                    <Nav.Link
                      id="tickets"
                      href="/ticket"
                      onClick={() => {
                        setExpanded(false);
                        resetTicket();
                        navigate("/ticket");
                      }}
                    >
                      <Icon name="plus" />
                      {t("ticket_create", { ns: "common" })}
                    </Nav.Link>
                    {checkApplication(Application.RealTimeLaborGuidePro) && (
                      <>
                        <Nav.Link
                          id="abandoned"
                          href="/abandoned"
                          onClick={() => {
                            setExpanded(false);
                          }}
                        >
                          <Icon name="minus circle" />
                          {t("in_progress", { ns: "common" })}
                        </Nav.Link>
                        <Nav.Link
                          id="recent"
                          href="/recent"
                          onClick={() => {
                            setExpanded(false);
                          }}
                        >
                          <Icon name="history" />
                          {t("recent", { ns: "common" })}
                        </Nav.Link>
                        <Nav.Link
                          id="customers"
                          href="/customers"
                          onClick={() => {
                            setExpanded(false);
                          }}
                        >
                          <Icon name="users" />
                          {t("customers", { ns: "common" })}
                        </Nav.Link>
                      </>
                    )}
                  </Nav>

                  {checkApplication(Application.RealTimeLaborGuidePro) && (
                    <Input
                      id="navSearchText"
                      name="navSearch"
                      className="search"
                      action={
                        <Dropdown
                          id="navSearchDropdown"
                          button
                          basic
                          floating
                          options={searchOptions}
                          value={dropdownValue}
                          onChange={(e, d) => {
                            setDropdownValue(Number(d.value!));
                            if (searchValue && d.value)
                              search(searchValue, Number(d.value));
                          }}
                        />
                      }
                      onKeyPress={(e: any, d: any) => {
                        if (
                          (e.nativeEvent.code === "Enter" ||
                            e.nativeEvent.code === "NumpadEnter") &&
                          searchValue &&
                          dropdownValue
                        )
                          search(searchValue, Number(dropdownValue));
                      }}
                      onChange={(e, d) => {
                        setSearchValue(d.value);
                      }}
                      onBlur={(e: any, d: any) => {
                        if (searchValue && dropdownValue)
                          search(searchValue, Number(dropdownValue));
                      }}
                      icon="search"
                      iconPosition="left"
                      placeholder="Search..."
                      value={searchValue}
                      disabled={location.pathname.startsWith("/search")}
                    />
                  )}
                </>
              )}

              <Dropdown
                id="userNavDropdown"
                item
                className="navbarPadding nav-link"
                // direction="left"
                text={user?.username}
              >
                <Dropdown.Menu>
                  {checkAccessLevel(AccessLevel.SystemAdministrator) && (
                    <Dropdown.Item
                      id="sysAdmin"
                      onClick={() => {
                        setExpanded(false);
                      }}
                      href={`/admin`}
                      text={t("system_admin", { ns: "common" })}
                      icon="detective"
                    />
                  )}

                  {checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
                    checkApplication(Application.RealTimeLaborGuidePro) &&
                    checkAccessLevel(AccessLevel.Manager) && (
                      <Dropdown.Item
                        id="reports"
                        onClick={() => {
                          setExpanded(false);
                        }}
                        href={`/reports`}
                        text={t("reports", { ns: "reports" })}
                        icon="table"
                      />
                    )}
                  {checkApplication(Application.AutomotiveExpert) && (
                    <>
                    <Dropdown.Item
                      onClick={() => {
                        setExpanded(false);
                      }}
                      as={Link}
                      to={`/inventoryIndex`}
                      text={t("inventory", { ns: "common" })}
                      icon="clipboard outline"
                    />
                    <Dropdown.Item
                      onClick={() => {
                        setExpanded(false);
                      }}
                      as={Link}
                      to={`/accountsReceivableIndex`}
                      text={t("Accounts Receivable", { ns: "common" })}
                      icon="money bill alternate outline"
                    />
                    </>
                  )}
                  {checkAccessLevel(AccessLevel.Manager) && (
                    <Dropdown.Item
                      id="settings"
                      onClick={() => {
                        setExpanded(false);
                      }}
                      href={`/settings`}
                      text={t("nav_settings", { ns: "settings" })}
                      icon="settings"
                    />
                  )}
                  {checkStatus(ClientStatus.Trial) &&
                    checkAccessLevel(AccessLevel.Owner) &&
                    (user?.application ?? 0) > Application.None && (
                      <>
                        <Dropdown.Item
                          id="staff"
                          onClick={() => {
                            setExpanded(false);
                          }}
                          href={`/staff`}
                          text={t("staff_management", { ns: "common" })}
                          icon="user"
                        />
                        <Dropdown.Item
                          id="subscription"
                          onClick={() => {
                            setExpanded(false);
                          }}
                          href={"/subscription/"}
                          text={t("subscription", { ns: "common" })}
                          icon="history"
                        />
                      </>
                    )}
                  <Dropdown.Item
                    id="changePassword"
                    onClick={() => {
                      setExpanded(false);
                    }}
                    href={`/users/changePassword/${user?.id}`}
                    text={t("password.change", { ns: "users" })}
                    icon="lock"
                  />
                  {new Auth().getToken("adminjwt") && (
                  <Dropdown.Item
                    id="restoreUser"
                    onClick={() => {
                      restoreUser().then(() => {
                        window.location.pathname = "/admin";
                        setExpanded(false);
                      });
                    }}
                    text="Restore User"
                    icon="user secret"
                  />                    
                  )}
                  <Dropdown.Item
                    id="logOut"
                    onClick={() => {
                      logout().then(() => {
                        resetTicket();
                        resetRecentTickets();
                        navigate("/");
                        setExpanded(false);
                      });
                    }}
                    text={t("logout", { ns: "common" })}
                    icon="power"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <Nav>
              <Menu.Item className="navbar-color">
                {t("login.application_online", { ns: "users" })}
              </Menu.Item>
            </Nav>
          )}
        </Navbar.Collapse>
      </>
    </Navbar>
  );
});
