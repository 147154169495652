import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import { CustomerTicket } from "../../app/models/ticket";
import { TicketType } from "../../app/models/enums";
import {
  formatCurrency,
  getTicketTypeColor,
} from "../../app/common/util/functions";
import { PagingParams } from "../../app/models/pagination";
import MyPaging from "../../app/common/form/MyPaging";
import { resetTicket } from "../../app/common/util/ticketfunctions";
import TicketStore from "../../app/stores/ticketStore";
import AlreadyInProgress from "../tickets/dashboard/modals/AlreadyInProgress";

interface Props {
  customerId: string;
}

export default observer(function CustomerDetailTicketList({
  customerId,
}: Props) {
  const { t } = useTranslation(["common", "customer"]);
  const navigate = useNavigate();

  const { modalStore, customerTicketStore, ticketStore } = useStore();

  const {
    getCustomerTickets,
    removeCustomerTicket,
    pagingParams,
    setPagingParams,
    pagination,
    resetCustomerTicketRegistry,
    loadCustomerTickets,
  } = customerTicketStore;

  const { getIsInProgress } = ticketStore;

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      resetCustomerTicketRegistry();
      loadCustomerTickets(customerId);
    }
  }

  return (
    <Table striped unstackable className="dnxTable">
      <Table.Header>
        <Table.Row key={"ticketheader"}>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("date", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("type", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("invoicenumber", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("vehicle", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("description", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("ponumber", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("total", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getCustomerTickets && getCustomerTickets?.length > 0 ? (
          getCustomerTickets.map((ticket: CustomerTicket) => (
            <Table.Row key={`ticket.${ticket.id}`}>
              <Table.Cell className="textAlignLeft">
                <Button
                  color="grey"
                  icon="edit"
                  type="button"
                  onClick={() => {
                    resetTicket();
                    getIsInProgress(ticket.id ?? "")
                      .then((result) => {
                        if (result && result.length > 0) {
                          modalStore.openWiderModal(
                            <AlreadyInProgress ticketId={ticket.id ?? ""} userName={result} />
                          );
                        } else {
                          navigate(`/ticket/summary/${ticket.id}/true`);
                        }
                      })
                      .catch();
                  }}
                ></Button>
              </Table.Cell>
              <Table.Cell data-label={`${t("date", { ns: "common" })}:`}>
                {ticket.createdDate && format(ticket.createdDate, "Pp")}
              </Table.Cell>
              <Table.Cell data-label={`${t("type", { ns: "common" })}:`}>
                <span
                  style={{
                    color: getTicketTypeColor(ticket.ticketType),
                  }}
                >
                  {t(
                    ticket.ticketType === TicketType.Invoice
                      ? "estimate.invoice"
                      : "estimate.est",
                    { ns: "tickets" }
                  )}
                </span>
              </Table.Cell>
              <Table.Cell data-label={`${t("invnum", { ns: "common" })}:`}>
                <span
                  style={{
                    color: getTicketTypeColor(ticket.ticketType),
                  }}
                >
                  {ticket.ticketType === TicketType.Invoice
                    ? ticket.invoiceNumber
                    : ticket.estimateNumber}
                </span>
              </Table.Cell>
              <Table.Cell data-label={`${t("vehicle", { ns: "common" })}:`}>
                {`${(ticket.year ?? 0) > 0 ? ticket.year : ""} ${
                  ticket.makeName ? ticket.makeName : ""
                } ${ticket.modelName}`}
              </Table.Cell>

              <Table.Cell data-label={`${t("description", { ns: "common" })}:`}>
                {ticket.description}
              </Table.Cell>
              <Table.Cell data-label={`${t("ponumber", { ns: "common" })}:`}>
                {ticket.poNumber}
              </Table.Cell>
              <Table.Cell data-label={`${t("total", { ns: "common" })}:`}>
                {formatCurrency(ticket.total ?? 0.0)}
              </Table.Cell>
              <Table.Cell className="textAlignRight" width={2}>
                <Button
                  color="grey"
                  icon="trash"
                  onClick={() => {
                    modalStore.openModal(
                      <ConfirmDelete
                        header={t("removeTicket", {
                          ns: "customer",
                        })}
                        value={ticket.id ? ticket.id : ""}
                        deleteValue={(d) => {
                          removeCustomerTicket(d).then(() => {});
                        }}
                      />
                    );
                  }}
                ></Button>
                <Button
                  color="grey"
                  icon="eye"
                  onClick={() => {
                    resetTicket();
                    navigate(`/ticket/view/${ticket.id}`);
                  }}
                ></Button>
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems3"}>
            <Table.Cell className="noPaddingLeft" width="9" colSpan={9}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan="9">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            />
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
