import {
  AbandonedTickets,
  CreateTicket,
  CustomerTicket,
  RecentTickets,
  SettingsTicketDefaults,
  SettingsTicketFormatting,
  SettingsTicketInputDefaults,
  SettingsTicketNumber,
  SettingsWorkflow,
  TicketHistoryItem,
  TicketSession,
} from "../models/ticket";
import { requests } from "./Requests";
import axios, { AxiosResponse } from "axios";
import { PaginatedResult } from "../models/pagination";
import { TicketWorkflowSteps } from "../models/enums";
import { IUpsertSelectableCommentFormValues, SelectableCommentsDto, UpsertSelectableCommentFormValues } from "../models/settings";
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const Tickets = {
  getSettingsTicketDefaults: () =>
    requests.get<SettingsTicketDefaults>(
      `/SettingsTicketDefaults/getSettingsTicketDefaults/`
    ),
  addSettingsTicketDefaults: (values: SettingsTicketDefaults) =>
    requests.post<void>(
      "/SettingsTicketDefaults/addSettingsTicketDefaults",
      values
    ),
  updateSettingsTicketDefaults: (values: SettingsTicketDefaults) =>
    requests.put<void>(
      `/SettingsTicketDefaults/updateSettingsTicketDefaults/${values.id}`,
      values
    ),

  getSettingsTicketFormatting: () =>
    requests.get<SettingsTicketFormatting>(
      `/SettingsTicketFormatting/getSettingsTicketFormatting/`
    ),
  addSettingsTicketFormatting: (values: SettingsTicketFormatting) =>
    requests.post<void>(
      "/SettingsTicketFormatting/addSettingsTicketFormatting",
      values
    ),
  updateSettingsTicketFormatting: (values: SettingsTicketFormatting) =>
    requests.put<void>(
      `/SettingsTicketFormatting/updateSettingsTicketFormatting/${values.id}`,
      values
    ),

  getSettingsTicketNumber: () =>
    requests.get<SettingsTicketNumber>(
      `/SettingsTicketNumber/getSettingsTicketNumber/`
    ),
  addSettingsTicketNumber: (values: SettingsTicketNumber) =>
    requests.post<void>(
      "/SettingsTicketNumber/addSettingsTicketNumber",
      values
    ),
  updateSettingsTicketNumber: (values: SettingsTicketNumber) =>
    requests.put<void>(
      `/SettingsTicketNumber/updateSettingsTicketNumber/${values.id}`,
      values
    ),

  getSettingsTicketInputDefaults: () =>
    requests.get<SettingsTicketInputDefaults>(
      `/SettingsTicketInputDefaults/getSettingsTicketInputDefaults/`
    ),
  addSettingsTicketInputDefaults: (values: SettingsTicketInputDefaults) =>
    requests.post<void>(
      "/SettingsTicketInputDefaults/addSettingsTicketInputDefaults",
      values
    ),
  updateSettingsTicketInputDefaults: (values: SettingsTicketInputDefaults) =>
    requests.put<void>(
      `/SettingsTicketInputDefaults/updateSettingsTicketInputDefaults/${values.id}`,
      values
    ),

  getSettingsWorkflow: () =>
    requests.get<SettingsWorkflow>(
      `/SettingsTicketWorkflow/getSettingsTicketWorkflow/`
    ),

  getSettingsSelectableCommments: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<SelectableCommentsDto[]>>(
        `/SettingsSelectableComment/getSelectableComments`,
        { params }
      ).then(responseBody),
  addSettingsSelectableCommment: (values: IUpsertSelectableCommentFormValues) =>
    requests.post<void>(
      `/SettingsSelectableComment/createSelectableComments`,
      values
    ),
  updateSettingsSelectableCommments: (commentId: string, values: IUpsertSelectableCommentFormValues) =>
    requests.put<void>(
      `/SettingsSelectableComment/updateSelectableComments/${commentId}`,
      values
    ),
  removeSettingsSelectableCommments: (commentId: string) =>
    requests.del<void>(
      `/SettingsSelectableComment/removeSelectableComments/${commentId}`
    ),

  addSettingsWorkflow: (values: SettingsWorkflow) =>
    requests.post<void>(
      "/SettingsTicketWorkflow/addSettingsTicketWorkflow",
      values
    ),
  updateSettingsWorkflow: (values: SettingsWorkflow) =>
    requests.put<void>(
      `/SettingsTicketWorkflow/updateSettingsTicketWorkflow/${values.id}`,
      values
    ),

  createCustomerTicket: (createTicket: CreateTicket) =>
    requests.post<void>("/ticket/createCustomerTicket", createTicket),

  listAbandonedTickets: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<AbandonedTickets[]>>(`/ticket/listTicketSessions`, {
        params,
      })
      .then(responseBody),

  removeTicketSession: (id: string) =>
    requests.put<void>(`/ticket/removeTicketSession/${id}`, {}),

  getTicketSessions: (
    id: string,
    workflowStep: TicketWorkflowSteps
  ) =>
    requests.get<TicketSession>(
      `/ticket/getTicketSessions?id=${id}&workflowStep=${workflowStep}`
    ),

    resetTicketSessions: (
      id: string,
      workflowStep: TicketWorkflowSteps
    ) =>
      requests.put<void>(
        `/ticket/resetTicketSessions?id=${id}&workflowStep=${workflowStep}`, {}
      ),

  getTicket: (id: string) =>
    requests.get<TicketSession>(`/ticket/getTicket?id=${id}`),

  updateTicketSessionUser: (id?: string) =>
    requests.put<void>(`/ticket/updateTicketSessionUser/${id}`, {}),

  updateTicketSession: (values: TicketSession, id?: string) =>
    requests.put<void>(`/ticket/updateTicketSession/${id}`, values),

  finalizeTicketSession: (values: TicketSession, id?: string) =>
    requests.put<void>(`/ticket/finalizeTicketSession/${id}`, values),
  reverseTicketPayments: (
    ticketId: string,
    ticketPaymentId: string,
    isPaymentProcess: boolean,
    amount: number
  ) => requests.put<void>(`/ticket/reverseTicketPayment/${ticketId}/${ticketPaymentId}/${isPaymentProcess}/${amount}`, {}),

  listRecentTickets: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<RecentTickets[]>>(`/ticket/listRecentTickets`, {
        params,
      })
      .then(responseBody),
  listCustomerTickets: (id: string, params: URLSearchParams) =>
    axios
      .get<PaginatedResult<CustomerTicket[]>>(
        `/ticket/listCustomerTickets/${id}`,
        {
          params,
        }
      )
      .then(responseBody),
  removeTicket: (id: string) =>
    requests.put<void>(`/ticket/removeTicket/${id}`, {}),
  assignTechnicianToTicketItem: (id: string, values: TicketSession) =>
    requests.put<void>(
      `/technician/assignTechnicianToTicketItem/${id}`,
      values
    ),
  listTicketHistory: (id: string) =>
    requests.get<TicketHistoryItem[]>(`/ticket/listTicketHistory/${id}`),

  getTicketPdf: (id: string) => requests.post<any>("/pdf/ticket", id),
  addTicketShopQuotedTimes: (id: string) =>
    requests.post<void>(`/ticket/addTicketShopQuotedTimes/${id}`, {}),
  addTicketMiscellaneousCharges: (id: string) =>
    requests.post<void>(`/ticket/addTicketMiscellaneousCharges/${id}`, {}),
  addQuickVinLogs: (id: string) =>
    requests.post<void>(`/ticket/addQuickVinLogs/${id}`, {}),

  getTicketInProgressStatus: (id: string) =>
    requests.get<string>(`/ticket/getTicketInProgressStatus/${id}`),

  validatePaymentTotals: (id: string) =>
    requests.post<void>(`/ticket/ValidatePaymentTotals/${id}`, {}),
};
